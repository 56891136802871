@import "../../styles/variables.scss";

.home {
  height: 90vh;
  width: 100%;
  padding: 0.5rem 3.5rem;
  margin-top: 10vh;
  font-family: $font-family-title;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .h-blur-top {
    position: absolute;
    top: 0;
    left: 90%;
  }
  .h-blur-buttom {
    position: absolute;
    bottom: 0;
    left: 10%;
  }

  .h-div {
    width: 90%;
    height: 20%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;


    .name-span {
      font-family: $font-family-title;
      color: #006600;
      opacity: 0;
      animation: animationName 3s 1;
      display: inline-block;
      animation-fill-mode:forwards;
      font-size: 2.5rem;
    }

    .tAnime {
      margin-left: 1rem;
      margin-bottom: 6rem;
      white-space: nowrap;
      .span {
        font-size: 2rem;
        font-family: $font-family-title;
        background-image: linear-gradient(180deg, #006600 20%, #ff9900 100%);
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        opacity: 0;
        animation: animation 10s 1 ;
        display: inline-block;
        animation-fill-mode:forwards;
      }
      span:nth-child(1) {
        animation-delay: 0.3s;
      }
      span:nth-child(2) {
        animation-delay: 0.9s;
      }
      span:nth-child(3) {
        animation-delay: 0s;
      }
      span:nth-child(4) {
        animation-delay: 0s;
      }
      span:nth-child(5) {
        animation-delay: 1.2s;
      }
      span:nth-child(6) {
        animation-delay: 0s;
      }
      span:nth-child(7) {
        animation-delay: 0.3s;
      }
      span:nth-child(8) {
        animation-delay: 0.9s;
      }
      span:nth-child(9) {
        animation-delay: 0.6s;
      }
      span:nth-child(10) {
        animation-delay: 0.3s;
      }
      span:nth-child(11) {
        animation-delay: 0.9s;
      }
      span:nth-child(12) {
        animation-delay: 0.6s;
      }
      span:nth-child(13) {
        animation-delay: 1.2s;
      }
    }

    .social {
      padding-right: 10%;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      // gap: 4.5rem;
      z-index: 1;

      .social-anim {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 4.5rem;
      }

      .circle {
        width: 3.5rem;
        height: 3.5rem;
        background: white;
        border-radius: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
  
        .h-icon {
          color: teal;
          font-size: 2rem;
        }
      }

      .circle::before {
        border-radius: 100%;
        content: '';
        background-image: linear-gradient(to bottom, #006600 0%, #ff9900 100%);
        top: -6px;
        left: -6px;
        bottom: -6px;
        right: -6px;
        position: absolute;  
        z-index:-1;
      }
    }
  }

  @keyframes animation {
    0% {opacity: 0; 
      transform: translateY(-100px) skewY(10deg) skewX(10deg) rotateZ(30deg);
      filter: blur(10px);
      -webkit-filter: blur(10px);
      -moz-filter: blur(10px);
      -ms-filter: blur(10px);
      }
      // 25% {opacity: 0; 
      //   transform: translateY(-100px) skewY(10deg) skewX(10deg) rotateZ(30deg);
      //   filter: blur(10px);
      //   }
    25% {opacity: 1;
      transform: translateY(0px) skewY(0deg) skewX(0deg) rotateZ(0deg);
      filter: blur(0px);
      -webkit-filter: blur(0px);
      -moz-filter: blur(0px);
      -ms-filter: blur(0px);
      }
    75% {opacity: 1;
      transform: translateY(0px) skewY(0deg) skewX(0deg) rotateZ(0deg);
      filter: blur(0px);
      -webkit-filter: blur(0px);
      -moz-filter: blur(0px);
      -ms-filter: blur(0px);
    }
    100% {opacity: 1;
      transform: translateY(0px) skewY(0deg) skewX(0deg) rotateZ(0deg);
      filter: blur(0px);
      -webkit-filter: blur(0px);
      -moz-filter: blur(0px);
      -ms-filter: blur(0px);
    }
    // 100% {opacity: 0;
    //   transform: translateY(-100px) skewY(10deg) skewX(10deg) rotateZ(30deg);
    //   filter: blur(10px);
    // }
  }

  @keyframes animationName {
    0% {opacity: 0; 
      transform: translateY(-50px) skewY(5deg) skewX(5deg) rotateZ(10deg);
      filter: blur(10px);
      -webkit-filter: blur(10px);
      -moz-filter: blur(10px);
      -ms-filter: blur(10px);
      }
    100% {opacity: 1;
      transform: translateY(0px) skewY(0deg) skewX(0deg) rotateZ(0deg);
      filter: blur(0px);
      -webkit-filter: blur(0px);
      -moz-filter: blur(0px);
      -ms-filter: blur(0px);
    }
  }

  @keyframes gradient {
    0% {background-position: 0%;}
    100% {background-position: 100%;}
    
  }  
}

@media screen and (max-width: 700px) {
  .home {
    padding: 0.5rem 2.5rem;
    .h-div {
      .name-span {
        font-size: 2.1rem;
      }
  
      .tAnime {
        .span {
          font-size: 1.7rem;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .home {
    padding: 0.5rem 2rem;
  }
}