@import "./styles/variables.scss";

.App{
  width: 100%;
  overflow: hidden;
  color: $font-color-main;
  position: relative;

  .blur {
    height: 300px;
    width: 300px;
    border-radius:  100%;

    // background:#ff3706;
    background: linear-gradient(45deg, #f17c58, #e94584 ,#24aad8 ,#27dbb1 ,#ffdc18 ,#ff3706);
    background-size: 100% 100%;
    filter: blur(90px);
    -webkit-filter: blur(90px);
    -moz-filter: blur(90px);
    -ms-filter: blur(90px);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
  }

  .footer {
    height: 5vh;
    bottom: 0;
    background-color: Teal;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
  }

  .top-to-btm{
    position: relative;

    .icon-position{
      position: fixed;
      bottom: 40px;
      right: 25px;
      z-index: 2;
    }
    .icon-style{
      background-color: #fff;
      border: 2px solid #fff;
      border-radius: 50%;
      height: 40px;
      width: 40px;
      color: #551B54;
      cursor: pointer;
      transition: all 0.5s ease-in-out;
    }
    .icon-style:hover{
      animation: none;
      background: #006600;
      color: #fff;
      border: 2px solid #551B54;
    }
  }

  .page-select {
    position: fixed;
    top: 40vh;
    right: 25px;
    z-index: 2;
    display: flex;
    flex-direction: column;

    ul {
      line-height: 40%;
    }
    .page-btn {
      font-size: 0.2rem;
      border-radius: 100%;
      background-color: #551B54;
      border: 1px solid #551B54;
      color: #fff;
      cursor: pointer;
      transition: all 0.5s ease-in-out;
    }

    .selected-page {
      color: #551B54;
    }
  }
}

@media screen and (min-width: 1400px) {
  .App {
    max-width: 1400px;
    margin: auto;
  }
}

@media screen and (max-height: 400px) {
  .App {
    .page-select {
      top: 20vh;
      right: 20px;
    }
  }
}