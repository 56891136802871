@import "../../styles/variables.scss";

.contact-form {
  min-height: 80vh;
  padding: 0.5rem 3.5rem;
  margin-top: 5rem;
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  position: relative;

  .c-blur-top {
    position: absolute;
    top: 0;
    left: 90%;
  }
  .c-blur-buttom {
    position: absolute;
    bottom: 0;
    left: 1%;
    z-index: -1;
  }

  .c-left {
    display: flex;
    flex-direction: column;
    font-family: $font-family-title;
    color: $font-color-main;
    margin-bottom: 2rem;
  }
  .c-left>:nth-child(1) {
    font-family: $font-family-title;
    font-weight: bold;
    font-size: 2.5rem;
  }

  .c-right {
    display: flex;
    justify-content: center;
    position: relative;
    flex: 1;

    form
    {
      display: flex;
      flex-direction: column;
      // gap: 2rem;
      align-items: center;
    }

    .user{
      width: 20rem;
      height: 3rem;
      padding: 0.3em;
      margin-bottom: 2rem;
      outline: none;
      border: 2px solid seagreen;
      border-radius: 8px;
      font-size: 16px;
        
    }
    textarea{
      min-height: 10rem!important;
      width: 20rem!important;
    }
    .c-button {
      @include g-button;
      padding: 12px 38px 12px 38px;
      margin-bottom: 2rem;
    }
  }
}

@media screen and (max-width: 700px) {
  .contact-form{
    padding: 0.5rem 2.5rem;
    .c-left>:nth-child(1) {
      font-size: 1.7rem;
    }
  }
}   

@media screen and (max-width: 500px) {
  .contact-form{
    padding: 0.5rem 2rem;
    flex-direction: column;
    // gap: 1rem;
    

    .c-right {
      margin-top: 1rem;
      .user {
        width: 16rem;
        width: 16rem!important;
      }
    }
  }
}