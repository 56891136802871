@import "../../styles/variables.scss";

.about {
  min-height: 87vh;
  margin-top: 3rem;
  margin-bottom: 3rem;
  padding: 0.5rem 3.5rem;
  padding-top: 3rem;
  display: flex;

  .a-left {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    // gap: 2rem;
    z-index: 3;
  
    .a-name {
      color: $font-color-text;
      display: flex;
      flex-direction: column;
      // gap: 0.5rem;
    }
  
    .a-name>* {
      font-weight: 100;
      font-size: 1rem;
      text-align:justify;
      margin-bottom: 0.5rem;
    }
  
    .a-name>:nth-child(1) {
      font-family: $font-family-title;
      color: $font-color-main;
      font-weight: bold;
      font-size: 2.5rem;
      margin-bottom: 15px;
    }
  
    .a-button {
      @include g-button;
      width: 11rem;
      height: 3rem;
      margin-top: 2rem;
    }
  }
  
  .a-right {
    position: relative;
    flex: 1.2;
    margin-left: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  
    .a-image-wrapper {
      height: 10px;
      width: 10px;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    img {
      position: absolute;
      transform: scale(1.6);
      z-index: 2;
    }
  
    @keyframes gradient {
      0% {background-position: 0%;}
      100% {background-position: 100%;}
    }
  
    .a-image-wrapper-blur {
      height: 400px;
      width: 400px;
      border-radius:  100%;
      position: absolute;
  
      background: linear-gradient(45deg, #f17c58, #e94584 ,#24aad8 ,#27dbb1 ,#ffdc18 ,#ff3706);
      background-size: 600% 100%;
      animation: gradient 30s linear infinite;
      animation-direction: alternate;
      filter: blur(82px);
      -webkit-filter: blur(82px);
      -moz-filter: blur(82px);
      -ms-filter: blur(82px);

      backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      transform: translate3d(0, 0, 0);
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
    }
  }
}

@media screen and (max-width: 700px) {
  .about {
    padding: 0.5rem 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .a-left {
      .a-name>:nth-child(1) {
        font-size: 1.7rem;
      }
    }
    .a-right {
      margin-left: 0rem;
      left: 30px;
      img {
        transform: scale(1.5);
        top: -170px;
      }
      .a-image-wrapper-blur {
        height: 350px;
        width: 350px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .about {
    padding: 0.5rem 2rem;
    .a-right {  
      padding-top: 12rem;
      img {
        transform: scale(1.3);
      }
      .a-image-wrapper-blur {
        height: 270px;
        width: 270px;
      }
    }
  }
}