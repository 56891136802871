@import "../../styles/variables.scss";

.projects {
  min-height: 90vh;
  padding: 0.5rem 3.5rem;
  margin-top: 6rem;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  position: relative;

  .p-blur-1 {
    position: absolute;
    top: 0;
    left: 90%;
    z-index: 0;
  }
  .p-blur-2 {
    position: absolute;
    top: 900px;
    left: -30px;
    z-index: 0;
  }
  .p-blur-3 {
    position: absolute;
    top: 1600px;
    left: 95%;
    z-index: 0;
  }

  .p-title {
    font-family: $font-family-title;
    color: $font-color-main;
    font-weight: bold;
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }

  .p-container {
    width: 86vw;
    margin: auto;
    padding-top: 4rem;
    padding-bottom: 4rem;
    display: flex;
    border-bottom: 2px solid black;
    z-index: 2;

    .p-left-container {
      flex: 1;
      padding-top: 1rem;
      padding-right: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;

      .p-l-c {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        // gap: 2rem;
      }

      img {
        height: 250px;
        width: 300px;
        border-radius: 20px;
        border: 1px solid;
        box-shadow: 20px 20px 16px #888888;
      }
    }
    
    .p-right-container {
      font-size: 0.9rem;
      flex: 1;
      display: flex;
      padding-top: 1rem;
      padding-left: 1rem;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      // gap: 1rem;

      .p-r-c {
        width: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // gap: 1rem;
      }

      .pro-title {
        font-size: 1.2rem;
        font-weight: bold;
      }
      .pro-description {
        font-size: 1rem;
        text-align: justify;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
      .pro-stack {
        font-size: 0.7rem;
        color: $font-color-text;
      }
      .pro-video-text {
        font-size: 1rem;
      }

      .stack-group {
        margin-top: 1rem;
        margin-bottom: 1rem;
      }

      .p-buttons {
        display: flex;
        justify-content: center;
        .p-button {
          @include g-button;
          border-radius: 90px 90px 90px 90px;
          width: 200px;
        }
        .p-button-1 {
          @include g-button;
          border-radius: 90px 0px 0px 90px;
          width: 100px;
        }
        .p-button-2 {
          @include g-button;
          border-radius: 0px 90px 90px 0px;
          width: 100px;
        } 
        .p-text-btn {
          border: none;
          color: black;
          font-size: 0.9rem;
          font-style: italic;
          text-decoration: underline;
          text-decoration-color: seagreen;
          background: transparent;
          text-underline-offset: 0.3rem;
          white-space: nowrap;

          -webkit-text-decoration: underline;
          -webkit-text-decoration-color: seagreen;
          -webkit-text-underline-offset: 0.3rem;
        
          &:hover {
            border: none;
            outline: none;
            background: transparent;
            cursor: pointer;
            color: $font-color-nav-button;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .projects {
    .p-container {
      .p-left-container {        
        img {
          height: 200px;
          width: 250px;
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .projects {
    padding: 0.5rem 2.5rem;
    .p-title {
      font-size: 1.7rem;
    }

    .p-container {
      flex-direction: column;
      
      .p-left-container {
        flex: 1;
        padding-top: 1rem;
        padding-right: 0rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .p-right-container {
        flex: 1;
        padding-top: 2rem;
        padding-left: 0rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .projects {
    padding: 0.5rem 2rem;
    margin-top: 5rem;
  }
}