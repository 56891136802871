// $font-family-main: "Source Sans Pro", sans-serif;
// $font-family-logo: "Sofia", sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Amaranth:ital,wght@1,700&display=swap');

// font
$font-color-main: #242D49;
$font-color-nav-button: MediumSeaGreen;
$font-color-text: #788097;
$font-color-sec: rgb(13, 62, 111);
$font-color-nav-btn: DimGray;

$font-family-main: "Karla", sans-serif;
$font-family-title: 'Amaranth', sans-serif;

// border
$test       : 2px solid black;
$test1      : 2px solid red;
$test2      : 2px solid blue;
$test3      : 2px solid green;


@mixin g-button {
  border: 1px solid white;
  // border: 1px solid teal;
  border-radius: 34px;
  color: white;
  font-size: 1.1rem;
  padding: 12px 28px 12px 28px;
  background: linear-gradient(160deg, hsla(148, 89%, 78%, 1) 0%, hsla(210, 81%, 22%, 1) 100%);
  // background: linear-gradient(180deg, #fdc50f 26.71%, #fb982f 99.36%);
  // box-shadow: 0px 20px 24px 3px hsl(120, 14%, 48%);
  box-shadow: 0px 20px 24px 3px hsl(120, 11%, 78%);
  transition: all 0.5s ease-in-out;

  @media (hover: hover) {
    &:hover {
      background: white;
      cursor: pointer;
      color: $font-color-nav-button;
      border: 1px solid $font-color-nav-button;
    }
  }
}


