@import "./styles/variables.scss";
* {
  box-sizing: border-box;
  font-family: $font-family-main;
}

::-webkit-scrollbar {
  width: 1rem;
  background: white;
}

::-webkit-scrollbar-track {
  background: white;
  border-radius: 100vw;
  margin-block: 0.5rem;
}
::-webkit-scrollbar-thumb {
  background: linear-gradient(160deg, hsla(148, 89%, 78%, 1) 0%, hsla(210, 81%, 22%, 1) 100%);
  border: 1.5px solid white;
  border-radius: 100vw;
}

body {
  margin: 0;
  font-size: 20px;
}

// all links
a {
  text-decoration : none;
  color: black;
  &:hover {
    color: black;
  }
  &:focus {
    outline: none;
  }
}
