@import "../../styles/variables.scss";

.skills {
  // border: $test1;
  min-height: 90vh;
  width: 100%;
  padding: 0.5rem 3.5rem;
  margin-top: 0rem;
  padding-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;

  .s-blur {
    position: absolute;
    height: 200px;
    width: 300px;
    bottom: 10px;
    border-radius:  100%;
    background: linear-gradient(45deg, #f17c58, #e94584 ,#24aad8 ,#27dbb1 ,#ffdc18 ,#ff3706);
    background-size: 100% 100%;
    filter: blur(90px);

    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
  }

  .s-left {
    flex: 1.5;
    display: flex;
    flex-direction: column;

    .s-title {
      font-family: $font-family-title;
      color: $font-color-main;
      font-weight: bold;
      font-size: 2.5rem;
    }
    
    .s-list {
      // border: $test1;
      padding-left: 20px;
      padding-right: 10px;
      margin-top: 50px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;

      .skill {
        // border: $test2;
        width: 80px;
        height: 110px;
        margin: 0.7rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        position: relative;

        .s-logo {
          // border: $test3;
          font-size: 3.5rem;
          margin-bottom: 0.3rem;
        }

        .s-name {
          // border: $test3;
          position: absolute;
          top: 70px;
          font-size: 1rem;
          white-space: wrap;
          text-align: center;
        }

        img {
          margin-top: 0.3rem;
          height: 55px;
        }
      }
    }
  }

  .s-right  {
    flex: 1;
    min-height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .scene {
      margin: 20px;
      width: 150px;
      height: 150px;
      
      perspective: 600px;
    }
    .cube {
      position: relative;
      width: inherit;
      height: inherit;
      
      transform-style: preserve-3d;
      transform: rotateY(30deg) rotateX(30deg);
      animation: rotateMe 10s infinite;
      
    }
    .cube-face {
      width: inherit;
      height: inherit;
      position: absolute;
      background: red;
      opacity: 0.8;
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 3rem;
      img {
        height: 50px;
      }
    }
    .cube-face-front {
      // background: yellow;
      background: silver;
      transform: translate3d(0, 0, 75px);
      background-image: radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%, transparent 86.18%), radial-gradient(66% 87% at 26% 20%, rgba(255, 255, 255, 0.41) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%);
  
    } 
    .cube-face-back {
      // background: orange;
      background: silver;
      transform: rotateY(180deg) translate3d(0, 0, 75px);
      background-image: radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%, transparent 86.18%), radial-gradient(66% 87% at 26% 20%, rgba(255, 255, 255, 0.41) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%);
  
    } 
    .cube-face-left {
      // background: green;
      background: silver;
      transform: rotateY(-90deg) translate3d(0, 0, 75px);
      background-image: radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%, transparent 86.18%), radial-gradient(66% 87% at 26% 20%, rgba(255, 255, 255, 0.41) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%);
  
    } 
    .cube-face-right {
      // background: magenta;
      background: silver;
      transform: rotateY(90deg) translate3d(0, 0, 75px);
      background-image: radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%, transparent 86.18%), radial-gradient(66% 87% at 26% 20%, rgba(255, 255, 255, 0.41) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%);
  
    } 
    .cube-face-top {
      // background: blue;
      background: silver;
      transform: rotateX(90deg) translate3d(0, 0, 75px);
      background-image: radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%, transparent 86.18%), radial-gradient(66% 87% at 26% 20%, rgba(255, 255, 255, 0.41) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%);
  
    } 
    .cube-face-bottom {
      // background: red;
      background: silver;
      transform: rotateX(-90deg) translate3d(0, 0, 75px);
      background-image: radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%, transparent 86.18%), radial-gradient(66% 87% at 26% 20%, rgba(255, 255, 255, 0.41) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%);
  
    }
  
    @keyframes rotateMe  {
      0% {
        transform: rotateY(30deg) rotateX(30deg);
      }
      11% {
        transform: rotateY(30deg) rotateX(120deg); 
      }
      22% {
        transform: rotateY(-130deg) rotateX(-30deg); 
      }
      33% {
        transform: rotateY(-130deg) rotateX(-120deg);
      }
      44% {
        transform: rotateY(-180deg) rotateX(20deg); 
      }
      55% {
        transform: rotateX(-90deg) rotateX(20deg); 
      }
      66% {
        transform: rotateY(-180deg) rotateX(20deg); 
      }
      77% {
        transform: rotateY(-130deg) rotateX(-120deg);
      }
      88% {
        transform: rotateY(-130deg) rotateX(-30deg); 
      }
      100% {
        transform: rotateY(30deg) rotateX(10deg); 
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .skills {
    padding: 0.5rem 2.5rem;
    margin-top: 12rem;

    .s-left {   
      
      .s-title {
        font-size: 1.7rem;
      }

      .s-list {
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 40px;
      }
    }
    .s-right {
      display: none;
    }
  }
}

@media screen and (max-width: 500px) {
  .skills {
    padding: 0.5rem 2.5rem;

    .s-blur {
      height: 150px;
      width: 150px;
    }

    .s-left {  
      
      .s-list {
        padding-left: 0px;
        padding-right: 0px;
  
        .skill {
          width: 70px;
          height: 90px;
          margin: 0.4rem;
  
          .s-logo {
            font-size: 3rem;
          }
  
          .s-name {
            top: 60px;
            font-size: 0.9rem;
          }
  
          img {
            margin-top: 0.3rem;
            height: 50px;
          }
        }
      }
    }
  }
}