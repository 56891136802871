@import "../../styles/variables.scss";

.n-wrapper, .n-wrapper-hidden{
  width: 100%;
  height: 10vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  transition: all 0.5s ease-in-out;

  .n-row {
    width: 100%;
    height: 100%;
    padding: 0 2rem;
    display: flex;
    justify-content: space-between;
  }

  .n-left {
    display: flex;
    flex: 1;
    align-items: center; 
    justify-content: flex-start;

    .logo-image{
      height: 7vh;
    }
  }

  .n-right {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;

    ul {
      display: flex;
      // gap: 2rem;
      margin-right: 4rem;
    
      li {
        font-size: 1.1rem;
        color: $font-color-nav-btn;
        margin-left: 2rem;
        transition: all 0.7s ease-in-out;
        
        text-underline-offset: 10px;
        text-decoration: underline;
        text-decoration-style: solid;
        text-decoration-color: white;
        text-decoration-thickness: 3px;

        -webkit-text-underline-offset: 10px;
        -webkit-text-decoration: underline;
        -webkit-text-decoration-style: solid;
        -webkit-text-decoration-color: white;
        -webkit-text-decoration-thickness: 3px;

        @media (hover: hover) {
          &:hover {
            cursor: pointer;
            color: $font-color-main;

            text-decoration-color: teal;
            -webkit-text-decoration-color: teal;
            
          }
        }
      }
    }
  }

  .n-sidebar {
    width: 100vw;
    height: 92vh;
    font-weight: 400;
    background: white;
    opacity: 0.96;
    position: absolute;
    top: 9vh;
    right: -500%;
    transition: all 0.4s ease-in-out;
    display: flex;
    justify-content: center;

    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      // gap: 2rem;
      padding-top: 3rem;
      
      li {
        font-size: 1.3rem;
        margin-left: -2.5rem;
        margin-bottom: 2rem;
        color: $font-color-nav-btn;
        transition: all 0.7s ease-in-out;

        text-underline-offset: 10px;
        text-decoration: underline;
        text-decoration-style: solid;
        text-decoration-color: white;
        text-decoration-thickness: 3px;

        -webkit-text-underline-offset: 10px;
        -webkit-text-decoration: underline;
        -webkit-text-decoration-style: solid;
        -webkit-text-decoration-color: white;
        -webkit-text-decoration-thickness: 3px;

        @media (hover: hover) {
          &:hover {
            cursor: pointer;
            color: $font-color-main;
            text-decoration-color: teal;
            -webkit-text-decoration-color: teal;
          }
        }
      }
    }
  }

  .active {
    right: 0px;
  }
}

.n-wrapper-hidden {
  top: -100px;
}


@media screen and (min-width: 1400px) {
  .n-wrapper, .n-wrapper-hidden {
    max-width: 1400px;
    margin: auto;
  }
}

@media screen and (max-width: 900px) {
  .n-wrapper, .n-wrapper-hidden {
    .n-right {
      ul {
        display: flex;
        // gap: 1.5rem;
        margin-right: 3rem;
      
        li {
          font-size: 1rem;
          margin-left: 1.5rem;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .n-wrapper, .n-wrapper-hidden {
    padding-left:  20px;
    padding-right:  20px;
  }
}
